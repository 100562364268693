<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <g fill="currentColor">
      <path
        d="M44.769 22.985c-.439-.334-10.867-8.165-21.274-5.691s-16.2 14.157-16.44 14.653a2.475 2.475 0 0 0 .906 3.177 32.788 32.788 0 0 0 15.747 4.608 21.52 21.52 0 0 0 4.984-.568c10.279-2.443 16.44-12.507 16.7-12.935a2.475 2.475 0 0 0-.623-3.244Zm-18.251 8.161a2.864 2.864 0 0 1-.457-5.71c.078-.006.156-.009.233-.009a2.866 2.866 0 0 1 2.85 2.636 2.867 2.867 0 0 1-2.626 3.083Zm-7.113-6.532a7.775 7.775 0 0 0 2.406 10.067 28.343 28.343 0 0 1-9.112-2.635 27.813 27.813 0 0 1 6.703-7.432Zm13.859 7.174a7.764 7.764 0 0 0-2.227-9.688 28.391 28.391 0 0 1 8.739 3.517 28.428 28.428 0 0 1-6.511 6.172Z"
      />
      <path d="m7.453 16.28 3.797-3.173 4.546 5.44L12 21.72z" />
      <path d="m0 25.772 2.702-4.147 5.926 3.861-2.702 4.147z" />
      <path d="m31.037 14.992 1.246-6.727 4.866.901-1.246 6.727z" />
      <path d="m40.537 17.535 3.133-5.668L48 14.261l-3.133 5.668z" />
      <path d="m19.048 9.536 4.803-1.197 1.627 6.529-4.803 1.196z" />
    </g>
  </svg>
</template>
